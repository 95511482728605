import React from 'react';
import { Link } from 'react-router-dom';

function PrivacyPolicy() {
  return (
    <div>
      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Privacy Policy</li>
          </ol>
        </div>
      </nav>

      <section class="contact_details sec-padd">
        <div class="container">
          <div class="section-title">
            <h2>Last Updated: 10 January 2025</h2>
          </div>
          <div class="text">
              <p>At CloudZen Solutions Pty Limited (“we”, “our”, or “us”), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and disclose information when you visit our website www.cloudzen.digial (“Site”) and use our services. By using our Site, you agree to the collection and use of information in accordance with this policy.</p>
          </div>
          <br />
          <div class="text">
            <p class="pt-20"> <strong>1. Information We Collect</strong></p>
            <p>We collect different types of information to provide and improve our services to you.
              <ul>
                <li><b>•  Personal Information:</b> When you visit our Site, register for an account, or contact us, we may collect personal information such as your name, email address, phone number, and any other information you provide.</li>
                <li><b>•  Usage Data:</b> We may also collect information about how our Site is accessed and used (“Usage Data”). This may include your computer’s Internet Protocol address (e.g., IP address), browser type, browser version, the pages of our Site that you visit, the time spent on those pages, and other diagnostic data.</li>
                <li><b>•  Cookies and Tracking Technologies:</b> We use cookies and similar tracking technologies to monitor activity on our Site. Cookies are small files placed on your device to enhance your user experience. You can choose to accept or decline cookies by adjusting your browser settings.</li>
              </ul>
            </p>
          </div>
          <br />
          <div class="text">            
            <p class="pt-20"> <strong>2. How We Use Your Information</strong></p>
            <p>We use the collected information for various purposes, including:
              <ul>
                <li>•  To provide and maintain our Site and services</li>
	              <li>•  To notify you about changes to our Site or services</li>
                <li>•  To respond to your inquiries and provide customer support</li>
	              <li>•  To analyze and improve the performance of our Site</li>
	              <li>•  To send marketing communications, if you have opted in</li>
	              <li>•  To monitor the usage of our Site and prevent any potential misuse</li>
              </ul>
            </p>
          </div>
          <br />
          <div class="text">   
            <p class="pt-20"> <strong>3. Sharing Your Information</strong></p>
            <p>We may share your personal information in the following cases:
              <ul>
                <li>•  With service providers: We may employ third-party companies and individuals to assist with the operation of our Site, including web hosting, email delivery, and payment processing. These third parties have access to your personal information only to perform specific tasks on our behalf and are obligated not to disclose or use it for other purposes.</li>
                <li>•  For legal reasons: We may disclose your personal information if required to do so by law or if we believe that such action is necessary to comply with a legal obligation or protect the rights, property, or safety of CloudZen Digital Pty Limited, our users, or others.</li>
              </ul>
            </p>
          </div>
          <br />
          <div class="text">   
            <p class="pt-20"> <strong>4. Security of Your Information</strong></p>
            <p>We take reasonable precautions to protect your personal information. However, please be aware that no method of transmission over the internet or electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security.</p>
          </div>
          <br />
          <div class="text">   
            <p class="pt-20"> <strong>5. Your Rights and Choices</strong></p>
            <p>Depending on your location, you may have certain rights regarding your personal information, including:
              <ul>
                <li>•  The right to access, correct, or delete your personal data</li>
	              <li>•  The right to object to or restrict the processing of your data</li>
	              <li>•  The right to withdraw your consent at any time (where consent is the basis for processing)</li>
              </ul>
            </p>
            <p>If you wish to exercise any of these rights, please contact us at roshan@cloudzen.digital</p>
          </div>
          <br />
          <div class="text">   
            <p class="pt-20"> <strong>6. Children’s Privacy</strong></p>
            <p>Our Site is not intended for children under the age of 13, and we do not knowingly collect personal information from children. If we become aware that we have collected personal information from a child under the age of 13, we will take steps to delete that information.</p>
          </div>
          <br />
          <div class="text">   
            <p class="pt-20"> <strong>7. Changes to This Privacy Policy</strong></p>
            <p>We may update our Privacy Policy from time to time. Any changes will be posted on this page with the “Last Updated” date at the top. We encourage you to review this policy periodically for any updates.</p>
            </div>
          <br />
          <div class="text">   
            <p class="pt-20"> <strong>8. Contact Us</strong></p>
            <p>If you have any questions about this Privacy Policy or the way we handle your personal information, please contact us at:</p>
            <p>CloudZen Solutions Pty Limited</p>
            <p>roshan@cloudzen.digital</p>
            <p>+230 5 840 1645</p>
            <p>22 Bis Dr Hassen Sakir Street, Port Louis, Mauritius</p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;