import React from 'react';
import { Link } from 'react-router-dom';

function BigData() {
  const divStyle = {
    backgroundImage: "url('/images/background/big-data-2.png')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div class="container">
          <div class="box">
            <h3>Big Data Analysis</h3>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Big Data Analysis</li>
          </ol>
        </div>
      </nav>

      <section className="content-section-full">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h3>Big Data Analysis</h3>
              <img src='/images/cloud1.jpg' alt="" 
                style={{
                    width: '500px',
                    height: 'auto',
                    float: 'right',
                    marginRight: '10px'}}/>
              <br/>
              
              <div className="text">
                <p>Unleash the power of your data to drive smarter decisions, discover new opportunities, and stay ahead in a competitive market. Our Big Data Analysis services help you transform massive amounts of data into actionable insights, empowering your business to make data-driven decisions with confidence.</p>
                <br/> 
                <p class="pt-20"> <strong>Our Big Data Solutions</strong></p>
                <p>We provide comprehensive services to manage and analyze your data:</p>
                <ul>
                  <li><b>•  Data Strategy & Consultation:</b> Collaborate with our experts to create a tailored data strategy aligned with your business goals.</li>
                  <li><b>•  Data Integration:</b> Consolidate and connect data from multiple sources into a unified platform for analysis.</li>
                  <li><b>•  Real-Time Analytics:</b> Monitor and analyze data streams in real time for faster decision-making.</li>
                  <li><b>•  Machine Learning & AI:</b> Unlock predictive insights and automate processes with advanced AI and ML algorithms.</li>
                  <li><b>•  Visualization & Reporting:</b> Transform complex data into intuitive dashboards and reports for easy interpretation.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Tools & Technologies</strong></p>
                <p>We leverage cutting-edge tools and platforms to deliver robust Big Data solutions:</p>
                <ul>
                  <li><b>•  Cloud Platforms:</b> AWS (Redshift, Athena), Microsoft Azure (Synapse Analytics), Google BigQuery.</li>
                  <li><b>•  Big Data Frameworks:</b> Apache Hadoop, Spark, Kafka.</li>
                  <li><b>•  Data Visualization Tools:</b> Tableau, Power BI, Looker.</li>
                  <li><b>•  Data Storage:</b> NoSQL Databases, Data Lakes, Data Warehouses.</li>
                  <li><b>•  AI & Machine Learning:</b> TensorFlow, PyTorch, AWS SageMaker.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Benefits of Big Data Analysis</strong></p>
                <ul>
                  <li><b>•  Enhanced Decision-Making:</b> Make informed decisions backed by deep insights.</li>
                  <li><b>•  Customer Understanding:</b> Gain a 360° view of your customers to improve engagement and personalization.</li>
                  <li><b>•  Operational Efficiency:</b> Identify inefficiencies and optimize workflows.</li>
                  <li><b>•  Market Insights:</b> Discover trends, opportunities, and potential risks to stay competitive.</li>
                  <li><b>•  Revenue Growth:</b> Drive innovation and uncover new revenue streams.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Why Choose Us?</strong></p>
                <ul>
                  <li><b>•  Expertise:</b> A team of certified data analysts and engineers with hands-on experience in diverse industries.</li>
                  <li><b>•  Tailored Solutions:</b> Strategies customized to meet your specific challenges and goals.</li>
                  <li><b>•  Scalable Services:</b> Solutions designed to grow with your data needs.</li>
                  <li><b>•  End-to-End Support:</b>  From strategy to execution and ongoing maintenance.</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BigData;