import React from 'react';
import { Link } from 'react-router-dom';

function ApplicationsTesting() {
    const divStyle = {
      backgroundImage: "url('/images/background/application-testing.jpg')", // Set the image URL
      backgroundSize: 'cover', // Ensure the image covers the div
      backgroundPosition: 'center', // Center the image
      backgroundRepeat: 'no-repeat', // Prevent the image from repeating
      width: '100%', // Set width to 100% or your preferred width
      height: '400px', // Set height to a fixed value or use a percentage
    };

    return (
        <div>
          <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
            <div class="container">
              <div class="box">
                <h3>Applications Testing</h3>
              </div>
            </div>
          </div>
    
          <nav aria-label="breadcrumb">
            <div className="container">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page"> Applications Testing</li>
              </ol>
            </div>
          </nav>
        </div>
    );
};

export default ApplicationsTesting;