import React, { useEffect, useState, useRef } from "react";
import { gapi } from "gapi-script";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from 'uuid';


function Appointment() {
  const divStyle = {
    backgroundImage: "url('/images/background/contact.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  const appointmentStyles = {
    container: {
      display: "flex",
      height: "100vh",
    },
    calendarContainer: {
      flex: 3, // 3/4 of the width
      padding: "20px",
      borderRight: "1px solid #ddd",
    },
    selectionContainer: {
      flex: 1, // 1/4 of the width
      padding: "20px",
    },
    textArea: {
      width: "100%",
      height: "100px",
      marginTop: "10px",
      marginBottom: "10px",
      padding: "10px",
      borderRadius: "5px",
      border: "1px solid #ccc",
      fontSize: "14px",
    },
    buttonContainer: {
      display: "flex",
      gap: "10px", // Space between buttons
    },
    button: {
      padding: "10px 20px",
      marginRight: "10px",
      backgroundColor: "#007BFF",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      textAlign: "center",
    },
    cancelButton: {
      padding: "10px 20px",
      backgroundColor: "#dc3545",
      color: "#fff",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
      textAlign: "center",
    },
  };

  const [events, setEvents] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const calendarRef = useRef(null); // Create a reference for the calendar instance
  const [message, setMessage] = useState("");

  const API_KEY = "AIzaSyCiR6CM5_Bu7XYIeUrx0PoxO3YiUH2-jJs";
  const CLIENT_ID = "cloudzen-public-portal"; //.apps.googleusercontent.com";
  const CALENDAR_ID = "roshan@cloudzen.digital";
  const SCOPES = "https://www.googleapis.com/auth/calendar.events";

  useEffect(() => {
    const fetchBusySlots = async () => {
      gapi.load("client", async () => {
        await gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: SCOPES,
        });

        const response = await gapi.client.calendar.freebusy.query({
          resource: {
            timeMin: new Date().toISOString(),
            timeMax: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString(),
            items: [{ id: CALENDAR_ID }],
          },
        });

        const busyTimes = response.result.calendars[CALENDAR_ID].busy;
        const formattedEvents = busyTimes.map((slot) => ({
          title: "Slot Occupied",
          start: slot.start,
          end: slot.end,
        }));
        setEvents(formattedEvents);
      });
    };

    fetchBusySlots();
  }, []);

  const handleDateSelect = (info) => {
    // Restrict selection to hourly slots
    const now = new Date(); // Current date and time
    const selectedStart = new Date(info.startStr);
    const endTime = new Date(selectedStart.getTime() + 60 * 60 * 1000); // Add 1 hour

    // Restrict past or same-day bookings
    if (
      selectedStart <= now || // Prevent bookings in the past
      selectedStart.toDateString() === now.toDateString() // Prevent bookings on the same day
    ) {
      Swal.fire({
        icon: "error",
        title: "Invalid Booking",
        text: "You cannot book appointments in the past or on the same day.",
      });

      info.view.calendar.unselect(); // Clear the selection
      return; // Prevent selection
    }
    
    // Save selected slot
    setSelectedSlot({
      start: selectedStart.toUTCString(),
      end: endTime.toUTCString(),
    });
  };

  const handleConfirmBooking = async () => {
    if (!selectedSlot) return;

    try {
      const authResponse = await gapi.auth2.getAuthInstance().signIn();
      if (!authResponse) {
        Swal.fire({
          icon: "error",
          title: "Authentication",
          text: "Failed to authenticate with Google!",
        });
        return;
      }
      const email = await gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile().getEmail();

      const event = {
        summary: "Booked Online Appointment with CloudZen",
        description: message || "No additional message provided.",
        start: {
          dateTime: selectedSlot.start,
          timeZone: "UTC",
        },
        end: {
          dateTime: selectedSlot.end,
          timeZone: "UTC",
        },
        conferenceData: {
          createRequest: {
            requestId: uuidv4(),
            conferenceSolutionKey: {
              type: 'hangoutsMeet'
            }
          }
        },
        attendees: [
          { email: email },  // Calendar owner's email
          { email: CALENDAR_ID },  // Website's Google email
        ],
      };

      const response = await gapi.client.calendar.events.insert({
        calendarId: CALENDAR_ID,
        resource: event,
        conferenceDataVersion: 1,
      });

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Booking Confirmed",
          text: "Appointment successfully booked!",
        });
        setEvents([...events, { title: "Booked", start: selectedSlot.start, end: selectedSlot.end }]);
        setSelectedSlot(null); // Clear selected slot
        setMessage(""); // Clear message
        const calendarApi = calendarRef.current.getApi();
        calendarApi.unselect();
      }
    } catch (error) {
      console.error("Error booking appointment:", error);
      Swal.fire({
        icon: "error",
        title: "Booking",
        text: "Failed to book the appointment. Please try again!",
      });
    }
  };

  return (
    <div>
      <div className="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div className="container">
            <div className="box">
              <h3>Appointments</h3>
            </div>
        </div>
      </div>

      <div style={appointmentStyles.container}>
        <div style={appointmentStyles.calendarContainer}>
          <FullCalendar
            plugins={[timeGridPlugin, interactionPlugin]}
            initialView="timeGridWeek"
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "timeGridWeek,timeGridDay",
            }}
            events={events}
            timeZone="UTC" // Configure calendar to use UTC timezone
            slotLabelFormat={{
              hour: "2-digit",
              minute: "2-digit",
              hour12: false, // Use 24-hour format
            }}
            selectable={true}
            hiddenDays={[0, 6]} // Hide Sunday (0) and Saturday (6)
            ref={calendarRef} // Attach the calendar instance to the ref
            select={handleDateSelect}
            slotDuration="01:00:00"
            slotLabelInterval="01:00"
            allDaySlot={false}
            selectMirror={true}
            unselectAuto={false} // Prevent automatic unselection on outside clicks
            eventColor="#378006"
            height="auto" // Dynamically adjusts height
            contentHeight="auto" // Ensures the content fits the container
          />
        </div>

        <div style={appointmentStyles.selectionContainer}>
          <h2>Book Your Appointment</h2>
          {selectedSlot ? (
            <div>
              <p>
                <strong>Start:</strong>{" "}
                {new Date(selectedSlot.start).toUTCString()} <br />
                <strong>End:</strong>{" "}
                {new Date(selectedSlot.end).toUTCString()}
              </p>
              <textarea
                style={appointmentStyles.textArea}
                placeholder="Enter a message for the appointment (optional)"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>

              <div style={appointmentStyles.buttonContainer}>
                <button style={appointmentStyles.button} onClick={handleConfirmBooking}>
                  Confirm Booking
                </button>
                <button
                  style={appointmentStyles.cancelButton}
                  onClick={() => {
                    // Cancel booking and unselect slot
                    setSelectedSlot(null); // Clear the state
                    const calendarApi = calendarRef.current.getApi(); // Get the calendar instance
                    calendarApi.unselect(); // Unselect the selected slot
                  }}
                >
                  Cancel Booking
                </button>
              </div>
            </div>
          ) : (
            <p>Select a time slot from the calendar to book an appointment.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Appointment;