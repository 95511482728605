import React from 'react';
import { Link } from 'react-router-dom';

function SystemIntegration() {
  const divStyle = {
    backgroundImage: "url('/images/background/cloud-software.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div class="container">
          <div class="box">
            <h3>Systems Integration</h3>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Systems Integration</li>
          </ol>
        </div>
      </nav>

      <section className="content-section-full">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h3>Systems Integration</h3>
              <img src='/images/service6.jpeg' alt="" 
                style={{
                    width: '500px',
                    height: 'auto',
                    float: 'right',
                    marginRight: '10px'}}/>
              <br/>
              
              <div className="text">
                <p>Modern businesses rely on seamless integration of systems and services to ensure efficient operations, scalability, and a competitive edge. Our System Integration services help you connect your applications, data, and workflows to create a unified, agile infrastructure.</p>
                <br/> 
                <p class="pt-20"> <strong>Our Approach</strong></p>
                <p>We specialize in designing and implementing robust integration solutions tailored to your unique requirements:</p>
                <ul>
                  <li><b>•  Consultation & Assessment:</b> Understanding your business processes and identifying integration needs.</li>
                  <li><b>•  Design & Strategy:</b> Creating a roadmap for integrating systems with minimal disruption.</li>
                  <li><b>•  Implementation:</b> Leveraging industry-leading tools and frameworks to connect applications and services.</li>
                  <li><b>•  Testing & Optimization:</b> Ensuring integrations are secure, efficient, and scalable.</li>
                  <li><b>•  Support & Maintenance:</b> Continuous monitoring and troubleshooting for sustained performance.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Integration Capabilities</strong></p>
                <p>We work with a wide range of platforms, systems, and technologies:</p>
                <ul>
                  <li><b>•  Cloud Platforms:</b> AWS, Microsoft Azure, Google Cloud Platform.</li>
                  <li><b>•  Enterprise Applications:</b> CRM, ERP, and custom business tools.</li>
                  <li><b>•  APIs & Middleware:</b> RESTful APIs, GraphQL, and middleware solutions.</li>
                  <li><b>•  Data Integration:</b> Connecting databases, data lakes, and analytics tools.</li>
                  <li><b>•  IoT & Edge Devices:</b> Bridging devices to your enterprise systems.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Benefits of Our Integration Services</strong></p>
                <ul>
                  <li><b>•  Improved Efficiency:</b> Streamlined processes and reduced manual effort.</li>
                  <li><b>•  Enhanced Scalability:</b> Systems that grow with your business needs.</li>
                  <li><b>•  Real-Time Insights:</b> Unified data for better decision-making.</li>
                  <li><b>•  Cost Savings:</b> Reduced redundancy and improved resource utilization.</li>
                  <li><b>•  Future-Ready Architecture:</b> Solutions designed for emerging technologies.</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SystemIntegration;