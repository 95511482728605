import React from 'react';
import { Link } from 'react-router-dom';

function EnterpriseApplications() {
    const divStyle = {
      backgroundImage: "url('/images/background/industries.jpg')", // Set the image URL
      backgroundSize: 'cover', // Ensure the image covers the div
      backgroundPosition: 'center', // Center the image
      backgroundRepeat: 'no-repeat', // Prevent the image from repeating
      width: '100%', // Set width to 100% or your preferred width
      height: '400px', // Set height to a fixed value or use a percentage
    };

    return (
      <div>
        <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
          <div class="container">
            <div class="box">
              <h3>Enterprise Applications</h3>
            </div>
          </div>
        </div>
  
        <nav aria-label="breadcrumb">
          <div className="container">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page"> Enterprise Applications</li>
            </ol>
          </div>
        </nav>

        <section className="content-section-full">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12">
                <h3>Enterprise Applications</h3>
                <img src='/images/service6.jpeg' alt="" 
                  style={{
                      width: '500px',
                      height: 'auto',
                      float: 'right',
                      marginRight: '10px'}}/>
                <br/>
                
                <div className="text">
                  <p>Transform your business operations with powerful enterprise applications that streamline workflows, improve collaboration, and deliver actionable insights. Our expertise ensures your business tools work seamlessly together, enabling you to focus on innovation and growth.</p>
                  <br/> 
                  <p class="pt-20"> <strong>Our Approach</strong></p>
                  <p>We provide end-to-end solutions for your enterprise needs:</p>
                  <ul>
                    <li><b>•  Application Development:</b> Build custom enterprise-grade applications tailored to your specific business requirements.</li>
                    <li><b>•  Implementation & Integration:</b> Seamlessly integrate enterprise tools like ERP, CRM, and HRMS into your existing ecosystem.</li>
                    <li><b>•  Cloud Migration:</b> Modernize legacy applications by migrating them to secure and scalable cloud environments.</li>
                    <li><b>•  Customization:</b> Adapt off-the-shelf software to align perfectly with your workflows and business processes.</li>
                    <li><b>•  Support & Upgrades:</b> Ensure smooth operation and stay ahead with regular updates and 24/7 support.</li>
                  </ul>
                  <br/> 
                  <p class="pt-20"><strong>Enterprise Tools We Work With</strong></p>
                  <p>We bring extensive experience with leading platforms and tools:</p>
                  <ul>
                    <li><b>•  CRM Systems:</b> Salesforce, Microsoft Dynamics 365.</li>
                    <li><b>•  ERP Solutions:</b> SAP, Oracle NetSuite, Microsoft Dynamics.</li>
                    <li><b>•  Collaboration Tools:</b> Microsoft 365, Google Workspace, Slack, Atlassian Suite.</li>
                    <li><b>•  Custom Solutions:</b> Tailored applications for industry-specific needs.</li>
                  </ul>
                  <br/> 
                  <p class="pt-20"><strong>Benefits of Enterprise Applications</strong></p>
                  <ul>
                    <li><b>•  Increased Productivity:</b> Automate repetitive tasks and streamline processes.</li>
                    <li><b>•  Improved Collaboration:</b> Foster teamwork with integrated tools that connect departments and teams.</li>
                    <li><b>•  Enhanced Decision-Making:</b> Leverage unified data and analytics for actionable insights.</li>
                    <li><b>•  Cost Optimization:</b> Reduce overhead with efficient, cloud-based solutions.</li>
                    <li><b>•  Scalability:</b> Grow your business with tools that adapt to your changing needs.</li>
                  </ul>
                </div>

              </div>
            </div>
          </div>
        </section>
      </div>
    );
};

export default EnterpriseApplications;