import React from 'react';
import { Link } from 'react-router-dom';

function MobileApplications() {
  const divStyle = {
    backgroundImage: "url('/images/background/industries.jpg')", // Set the image URL
    backgroundSize: 'cover', // Ensure the image covers the div
    backgroundPosition: 'center', // Center the image
    backgroundRepeat: 'no-repeat', // Prevent the image from repeating
    width: '100%', // Set width to 100% or your preferred width
    height: '400px', // Set height to a fixed value or use a percentage
  };

  return (
    <div>
      <div class="inner-banner has-base-color-overlay text-center" style={divStyle}>
        <div class="container">
          <div class="box">
            <h3>Mobile Applications</h3>
          </div>
        </div>
      </div>

      <nav aria-label="breadcrumb">
        <div className="container">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page"> Mobile Applications</li>
          </ol>
        </div>
      </nav>

      <section className="content-section-full">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <h3>Mobile Applications</h3>
              <img src='/images/cloud1.jpg' alt="" 
                style={{
                    width: '500px',
                    height: 'auto',
                    float: 'right',
                    marginRight: '10px'}}/>
              <br/>
              
              <div className="text">
                <p>Empower your business and engage your customers with cutting-edge mobile applications tailored to your needs. Our Mobile Application services combine innovation, design, and functionality to deliver seamless user experiences across all devices.</p>
                <br/> 
                <p class="pt-20"> <strong>Our Mobile Application Services</strong></p>
                <p>We offer end-to-end mobile app development solutions:</p>
                <ul>
                  <li><b>•  Custom App Development:</b> Build bespoke mobile applications designed specifically for your business requirements.</li>
                  <li><b>•  Cross-Platform Development:</b> Create apps that work flawlessly on iOS and Android using frameworks like React Native and Flutter.</li>
                  <li><b>•  UI/UX Design:</b> Design intuitive, visually stunning interfaces to enhance user engagement.</li>
                  <li><b>•  App Integration:</b> Seamlessly connect your mobile apps with enterprise systems, APIs, and third-party services.</li>
                  <li><b>•  Maintenance & Updates:</b> Ensure your app stays secure, updated, and optimized for performance.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Our Expertise</strong></p>
                <p>We specialize in creating diverse types of mobile applications:</p>
                <ul>
                  <li><b>•  Business Apps:</b> CRM tools, productivity apps, and custom business solutions.</li>
                  <li><b>•  E-Commerce Apps:</b> Mobile storefronts with secure payment gateways and intuitive navigation.</li>
                  <li><b>•  On-Demand Apps:</b> Solutions for ride-sharing, food delivery, and more.</li>
                  <li><b>•  Social Media Apps:</b> Engaging platforms with features like messaging, sharing, and live streaming.</li>
                  <li><b>•  IoT & Wearable Apps:</b> Apps for smart devices, fitness trackers, and connected home solutions.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Technologies We Use</strong></p>
                <p>We leverage the latest tools and frameworks to deliver powerful and reliable apps:</p>
                <ul>
                  <li><b>•  Languages:</b> Swift, Kotlin, C#, C++, TypeScript, Python, GO Lang.</li>
                  <li><b>•  Frameworks:</b> React Native, .NET, Flutter, Ionic</li>
                  <li><b>•  Cloud Integration:</b> AWS Mobile Hub, Firebase, Azure App Services.</li>
                  <li><b>•  Testing Tools:</b> Appium, TestFlight, Selenium.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Benefits of Our Mobile Applications</strong></p>
                <ul>
                  <li><b>•  Enhanced Customer Engagement:</b> Deliver personalized and accessible experiences to your users.</li>
                  <li><b>•  Increased Revenue:</b> Unlock new channels for sales and customer interaction.</li>
                  <li><b>•  Brand Visibility:</b> Strengthen your brand presence with a sleek and professional app.</li>
                  <li><b>•  Scalability:</b>  Apps designed to grow as your business expands.</li>
                  <li><b>•  Faster Time to Market:</b>  Agile development processes to launch your app quickly.</li>
                </ul>
                <br/> 
                <p class="pt-20"><strong>Why Partner With Us?</strong></p>
                <ul>
                  <li><b>•  Creative Designers:</b> Apps that are as visually stunning as they are functional.</li>
                  <li><b>•  Experienced Developers::</b> A team skilled in the latest mobile technologies.</li>
                  <li><b>•  User-Centric Approach:</b> Applications built with the end-user in mind.</li>
                  <li><b>•  Proven Track Record:</b>  Successful delivery of apps across multiple industries.</li>
                </ul>
              </div>

            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MobileApplications;